<template>
  <div id="scenario-detail-page">
    <section class="for-breadcumb">
      <div class="container">
        <ul class="breadcrumb">
          <li>
            <router-link to="/home">Home</router-link>
          </li>
          <li>
            <router-link to="/dashboard">Dashboard</router-link>
          </li>
          <li>
            <router-link to="/scenario">Crisis Scenario</router-link>
          </li>
          <li>
            {{ scenario.title }}
          </li>
        </ul>
      </div>
    </section>

    <div class="news-content">
      <div class="container">
        <div class="row gx-5">
          <div class="col-lg-7">
            <img :src="scenario.banner_url" class="img-fluid w-100">
            <div class="detail-article">
              <h2 class="detail-title">{{ scenario.title }}</h2>
              <div class="db-news-date">{{ scenario.date }}</div>
              <p v-html="scenario.description"></p>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="detail-title mb24">Recent Crisis Scenario</div>
            <div class="dashboard-item main-list mb30">
              <template v-for="(recent, index) in recents">
                <router-link :to="`/scenario/${recents.slug}`" class="crisis-list-items" :key="index">
                  <div class="row">
                    <div class="col-lg-4 col-sm-4">
                      <div class="crisis-img">
                        <figure>
                          <img :src="recent.banner_url" class="img-fluid">
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-8 col-sm-8">
                      <h6>{{ recent.title }}</h6>
                      <p>{{ recent.description | short_description(75) }}</p>
                      <div class="db-news-date mb-0">{{ recent.date }}</div>
                    </div>
                  </div>
                </router-link>
              </template>
              <div class="d-flex justify-content-end">
                <router-link to="/scenario" class="link-underline">View All</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScenarioDetail",
  data() {
    return {
      scenario: {},
      recents: []
    }
  },
  mounted() {
    this.getDetail(),
    this.getRecents()
  },
  methods: {
    getDetail() {
      this.$axios.get(`/master/crisis_scenario/${this.$route.params.slug}`)
        .then(response => {
          this.scenario = response.data.data
        })
    },
    getRecents() {
      this.$axios.get(`/master/crisis_scenario/recent`)
        .then(response => {
          this.recents = response.data.data
        })
    }
  }
}
</script>